////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(236, 244, 250, 1);
}
.bg-secondaryColor {
  background-color: rgba(186, 203, 221, 1);
}
.bg-thirdColor {
  background-color: rgba(186, 203, 221, 1);
}
.bg-fourthColor {
  background-color: rgba(5, 38, 89, 1);
}
.bg-fifthColor {
  background-color: rgba(251, 152, 51, 1);
}
.bg-sixthColor {
  background-color: rgb(0, 0, 0);
}
.bg-seventhColor {
  background-color: rgba(236, 244, 250, 1);
}
